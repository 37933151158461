import { patchFeatureFlag } from '@gonfalon/openapi';
import { getQueryClient } from '@gonfalon/react-query-client';

import { createMutationHook } from './internal/createMutationHook';
import { getFeatureFlagQuery, getFeatureFlagsQuery } from './flags';

async function patchSemantically(
  input: Parameters<typeof patchFeatureFlag>[0],
  options: Parameters<typeof patchFeatureFlag>[1] = {},
) {
  return patchFeatureFlag(input, {
    ...options,
    headers: {
      ...options.headers,
      'Content-Type': 'application/json; domain-model=launchdarkly.semanticpatch',
    },
  });
}

export const useUpdateFlagSemantically = createMutationHook(patchSemantically, {
  onSuccess: async (response, { projectKey }) => {
    const queryClient = getQueryClient();
    await queryClient.invalidateQueries({ queryKey: getFeatureFlagsQuery.partialQueryKey() });
    queryClient.setQueryData(getFeatureFlagQuery({ projectKey, featureFlagKey: response.key }).queryKey, response);
  },
});
